<template>
  <div class="home">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="hero-content">
        <img src="/images/logo.png" alt="The DJ's Vault" class="hero-logo" />
        <p class="hero-description">
          You are about to enter a community of thousands of mobile DJs around
          the world. Use the information inside and the connections you make to
          take your company to the next level.
          <br />As always,
          <a href="mailto:help@thedjsvault.com" class="support-link"
            >email us</a
          >
          if you have any questions.
        </p>
      </div>
    </section>

    <!-- Book Banner Image -->
    <section class="content-section">
      <div class="container">
        <div class="book-banner-container">
          <a
            href="https://calendly.com/joebunn/oneonone"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/bookbunn.png?alt=media&token=ba69e416-816f-4080-8332-d553c8e63eca"
              alt="Book Banner"
              class="book-banner-image"
            />
          </a>
        </div>
      </div>
    </section>

    <!-- Latest Videos Section -->
    <section class="content-section">
      <div class="container">
        <div class="video-grid-container">
          <h2 class="section-title">Latest Videos</h2>
          <div class="video-grid">
            <div
              v-for="post in posts"
              :key="post ? post.id : Math.random()"
              class="video-item"
            >
              <post-card v-if="post" :post="post"></post-card>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Most Watched Section -->
    <section
      v-if="mostWatchedPosts && mostWatchedPosts.length"
      class="content-section"
    >
      <div class="container">
        <div class="video-grid-container">
          <h2 class="section-title">Most Watched</h2>
          <div class="video-grid">
            <div
              v-for="post in mostWatchedPosts"
              :key="post ? post.id : Math.random()"
              class="video-item"
            >
              <post-card v-if="post" :post="post"></post-card>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Events Section -->
    <Events />

    <!-- Learning Paths Section -->
    <section class="content-section">
      <div class="container">
        <div
          v-for="path in paths"
          :key="path ? path.id : Math.random()"
          class="learning-path"
        >
          <h2 class="section-title">{{ path.title }}</h2>
          <div class="video-grid">
            <div
              v-for="post in path.posts"
              :key="post ? post.id : Math.random()"
              class="video-item"
            >
              <post-card v-if="post" :post="post"></post-card>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Partners Section -->
    <section class="content-section">
      <div class="container">
        <div class="ads-grid">
          <h2 class="section-title">Preferred Partners</h2>
          <div class="ads-container">
            <div
              v-for="ad in ads"
              :key="ad ? ad.id : Math.random()"
              class="ad-item"
            >
              <a
                v-if="ad"
                :href="getPartnerLink(ad)"
                target="_blank"
                class="ad-link"
              >
                <img
                  :src="ad.image"
                  class="ad-image"
                  :alt="ad.title || 'Partner'"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// import slider from "../components/Slider.vue";

const axios = require("axios");
import { db } from "@/db.js";
import PostCard from "../components/PostCard.vue";
import Events from "@/components/Events.vue";
import moment from "moment";

export default {
  name: "Home",
  components: {
    VueSlickCarousel,
    PostCard,
    Events,
  },
  data() {
    return {
      posts: [],
      postsFavorites: [],
      postsSuggested: [],
      educators: [],
      paths: [],
      postsSales: [],
      postsMultiOp: [],
      awsPosts: [],
      showWelcomeVideo: false,
      ads: [],
      welcomeVideo:
        "https://firebasestorage.googleapis.com/v0/b/thedjsvault-307019.appspot.com/o/welcome.mov?alt=media&token=80019ac7-dfc0-45c7-933a-b1c353de3f06",
      baseUrl: this.$store.state.baseUrlWF,
      uid: "",
      categories: [
        {
          id: 1,
          name: "Marketing",
          path: "/section/marketing",
          icon: "fa fa-bullhorn",
          description: "Grow your business with proven marketing strategies",
        },
        {
          id: 2,
          name: "Sales",
          path: "/section/sales",
          icon: "fa fa-dollar",
          description: "Convert leads and close more deals",
        },
        {
          id: 3,
          name: "Performance",
          path: "/section/performance",
          icon: "fa fa-music",
          description: "Enhance your DJ skills and techniques",
        },
        {
          id: 4,
          name: "Gear",
          path: "/section/gear",
          icon: "fa fa-sliders",
          description: "Stay updated with the latest equipment",
        },
        {
          id: 5,
          name: "Office Hours",
          path: "/section/officehours",
          icon: "fa fa-clock-o",
          description: "Get expert advice and guidance",
        },
        {
          id: 6,
          name: "Documents",
          path: "/documents",
          icon: "fa fa-file-text",
          description: "Access essential business templates",
        },
      ],
      mostWatchedPosts: [],
      upcomingEvents: [],
      partnerLinks: {
        photobooth: "https://www.photoboothplus.com/ref/thedjsvault/",
        glotubes: "https://www.glotubes.com/?ref=thedjsvault",
        bothlighting: "https://www.bothlighting.com/?ref=thedjsvault",
      },
    };
  },
  async mounted() {
    try {
      await this.getPosts();
      await this.getMostWatched();
    } catch (error) {
      console.error("Error initializing home page:", error);
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
    },
    getPartnerLink(ad) {
      // Logic to return correct partner link based on ad
      if (ad.type === "photobooth") return this.partnerLinks.photobooth;
      if (ad.type === "glotubes") return this.partnerLinks.glotubes;
      if (ad.type === "bothlighting") return this.partnerLinks.bothlighting;
      return ad.link;
    },
    getPosts() {
      // Latest Videos
      this.$bind(
        "posts",
        db
          .collection("posts")
          .orderBy("NewOrder", "desc")
          .limit(24)
      )
        .then(() => {
          let posts = this.posts || [];
          const filteredPosts = posts.filter((post) => {
            return (
              post &&
              post.Video &&
              post.Video.trim() !== "" &&
              post.Section !== "mayhem" &&
              post.Section !== "annual" &&
              post.Subsection !== "logo reviews" &&
              (this.$store.state.currentUser.membershipLevel !== "lite" ||
                post.lite === true)
            );
          });
          this.posts = filteredPosts.slice(0, 12);
        })
        .catch((error) => {
          console.error("Error loading posts:", error);
          this.posts = [];
        });

      // Get paths from Firestore
      this.$bind("paths", db.collection("paths").orderBy("order", "asc"))
        .then(() => {
          // Filter out empty videos from path posts
          this.paths = (this.paths || [])
            .map((path) => {
              if (path && path.posts) {
                const validPosts = path.posts.filter(
                  (post) =>
                    post &&
                    post.Video &&
                    post.Video.trim() !== "" &&
                    (this.$store.state.currentUser.membershipLevel !== "lite" ||
                      post.lite === true)
                );
                path.posts = validPosts.slice(0, 6);
              }
              return path;
            })
            .filter((path) => path && path.posts && path.posts.length > 0);

          console.log("Paths loaded:", this.paths);
        })
        .catch((error) => {
          console.error("Error loading paths:", error);
          this.paths = [];
        });

      this.$bind(
        "educators",
        db.collection("educators").where("active", "==", true)
      ).then(function() {});

      this.$bind(
        "postsSales",
        db
          .collection("posts")
          .where("Section", "==", "sales")
          .orderBy("PublishedDate", "desc")
          .limit(4)
      ).then(function() {});

      this.$bind(
        "postsMultiOp",
        db
          .collection("posts")
          .where("Section", "==", "multi-op")
          .orderBy("PublishedDate", "desc")
          .limit(4)
      ).then(function() {});

      this.$bind(
        "ads",
        db
          .collection("ads")
          .where("active", "==", true)
          .limit(20)
      )
        .then(() => {
          let ads = this.ads || [];
          let shuffled = ads
            .filter((ad) => ad && ad.image)
            .sort(() => Math.random() - 0.5);
          this.ads = shuffled.slice(0, 6);
          console.log("Ads loaded:", this.ads);
        })
        .catch((error) => {
          console.error("Error loading ads:", error);
          this.ads = [];
        });
    },
    getMostWatched() {
      console.log("Starting getMostWatched");
      this.$bind(
        "mostWatchedPosts",
        db.collection("posts").where("Video", "!=", "")
      )
        .then(() => {
          console.log("Raw posts:", this.mostWatchedPosts);
          let posts = this.mostWatchedPosts || [];
          // Sort posts by Viewed array length and filter empty videos
          posts = posts.filter(
            (post) =>
              post &&
              post.Video &&
              post.Video.trim() !== "" &&
              post.Section !== "mayhem" &&
              post.Section !== "annual" &&
              post.Subsection !== "logo reviews" &&
              (this.$store.state.currentUser.membershipLevel !== "lite" ||
                post.lite === true)
          );

          // Sort by the actual length of valid views in the Viewed array
          posts.sort((a, b) => {
            const aViews = Array.isArray(a.Viewed)
              ? a.Viewed.filter((v) => v).length
              : 0;
            const bViews = Array.isArray(b.Viewed)
              ? b.Viewed.filter((v) => v).length
              : 0;
            return bViews - aViews;
          });

          this.mostWatchedPosts = posts.slice(0, 12);
          console.log("Total number of videos processed:", posts.length);
          console.log(
            "Sorted most watched posts:",
            this.mostWatchedPosts.map((p) => ({
              title: p.Title,
              views: p.Viewed ? p.Viewed.filter((v) => v).length : 0,
            }))
          );
        })
        .catch((error) => {
          console.error("Error loading most watched posts:", error);
          this.mostWatchedPosts = [];
        });
    },
    getFavorites() {
      const _this = this;
      this.$bind(
        "postsFavorites",
        db
          .collection("posts")
          .where(
            "favorites",
            "array-contains",
            this.$store.state.currentUser.uid
          )
      ).then(function() {
        console.log("postsFavorites", _this.postsFavorites);
      });
    },
    getAds() {
      this.$bind(
        "ads",
        db
          .collection("ads")
          .where("active", "==", true)
          // .orderBy("order", "desc")
          .limit(20)
      ).then(() => {
        let shuffled = this.ads.sort(() => Math.random() - 0.5);

        this.ads = shuffled.slice(0, 6);
        console.log("SHUFFLED", this.ads);
      });
    },
  },
};
</script>

<style scoped>
/* a {
  color: red;
  font-weight: bolder;
} */
#iq-favorites {
  max-width: 1180px;
  margin: auto;
}
.channel-logo {
  width: 100%;
}
/* #home-slider .slick-bg {
  height: 440px;
} */
.home {
  padding-top: 60px;
  background-color: #151515;
  min-height: 100vh;
}

/* Container and Content Sections */
.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(rgba(21, 21, 21, 0.9), rgba(21, 21, 21, 0.7)),
    url("/images/doc.jpg") center/cover;
  padding: 60px 0;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  width: 100%;
}

.hero-content {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

.hero-logo {
  max-width: 300px;
  margin-bottom: 30px;
}

.hero-description {
  max-width: 800px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.hero-title {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.lite-badge {
  display: inline-block;
  background: #eb9e34;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 1.5rem;
  vertical-align: middle;
  margin-left: 10px;
  font-style: italic;
}

.hero-description {
  font-size: 1.1rem;
  margin-bottom: 0;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

.support-link {
  color: #eb9e34;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.support-link:hover {
  color: #d88f2a;
}

/* Content Sections */
.content-section {
  margin-bottom: 30px;
  width: 100%;
}

/* Individual Row Styling */
.learning-path,
.video-grid-container,
.ads-grid {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 30px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.learning-path:hover,
.video-grid-container:hover,
.ads-grid:hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s ease;
}

.learning-path:last-child,
.video-grid-container:last-child,
.ads-grid:last-child {
  margin-bottom: 0;
}

.section-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
  text-transform: capitalize;
}

/* Video Grid */
.video-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
}

.video-item,
.ad-item {
  transition: transform 0.3s ease;
  min-width: 0;
}

.video-item:hover,
.ad-item:hover {
  transform: translateY(-5px);
}

/* Video Container */
.video-container {
  max-width: 800px;
  margin: 0 auto 40px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.video-container video {
  width: 100%;
  display: block;
}

/* Ads Grid */
.ads-grid {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.02);
  padding: 30px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

.ads-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.ad-item {
  transition: transform 0.3s ease;
  min-width: 0;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-link {
  display: block;
  width: 100%;
  height: 100%;
}

.ad-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.ad-image:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
}

/* Learning Path */
.learning-path {
  margin-bottom: 20px;
}

.learning-path:last-child {
  margin-bottom: 0;
}

/* Responsive Design - Only for video grid, not partners */
@media (max-width: 1600px) {
  .video-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 1400px) {
  .video-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1200px) {
  .video-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .container,
  .hero-content {
    padding: 0 15px;
  }

  .learning-path,
  .video-grid-container,
  .ads-grid {
    padding: 20px;
    margin-bottom: 20px;
  }

  .video-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .ads-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .video-grid {
    grid-template-columns: 1fr;
  }

  .ads-container {
    grid-template-columns: 1fr;
  }
}

/* Update footer copyright */
.footer-copyright {
  text-align: center;
  padding: 20px;
  color: rgba(255, 255, 255, 0.7);
}

/* Events Section */
.events-container {
  width: 100%;
}

.events-container :deep(.section-title) {
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
  text-transform: capitalize;
}

/* Book Banner Styles */
.book-banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.book-banner-image {
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.book-banner-image:hover {
  transform: translateY(-5px);
}
</style>

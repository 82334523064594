<template>
  <footer class="footer">
    <div class="footer-container">
      <!-- Support Section -->
      <div class="footer-section">
        <div class="support-info">
          <span>Need help? Email us at </span>
          <a href="mailTo:help@thedjsvault.com" class="support-link">help@thedjsvault.com</a>
        </div>
      </div>

      <!-- Copyright and Links Section -->
      <div class="footer-section">
        <div class="footer-bottom">
          <span class="copyright">© 2025 The DJs Vault. All rights reserved.</span>
          <div class="footer-links">
            <a href="https://www.iubenda.com/privacy-policy/93415460/legal" target="_blank" class="footer-link">Privacy Policy</a>
            <a href="https://www.iubenda.com/privacy-policy/93415460/cookie-policy" target="_blank" class="footer-link">Cookie Policy</a>
            <a href="https://www.iubenda.com/terms-and-conditions/93415460" target="_blank" class="footer-link">Terms and Conditions</a>
          </div>
        </div>
      </div>

      <!-- Legal Text -->
      <div class="footer-section">
        <div class="legal-text">
          The information provided on this website is for general information purposes only. All content and materials are provided "as is" without warranty of any kind. The views expressed are those of The DJ's Vault only. Users should seek professional advice for specific legal, financial, or accounting matters.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style scoped>
.footer {
  width: 100%;
  padding: 40px 0;
}

.footer-container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 30px;
}

.footer-section {
  margin-bottom: 30px;
  text-align: center;
}

.footer-section:last-child {
  margin-bottom: 0;
}

.support-info {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

.support-link {
  color: #eb9e34;
  text-decoration: none;
  transition: color 0.2s ease;
}

.support-link:hover {
  color: #d88f2a;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.footer-links {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
}

.footer-link {
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.footer-link:hover {
  color: #fff;
}

.copyright {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
}

.legal-text {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.8rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .footer {
    padding: 30px 0;
  }

  .footer-container {
    padding: 0 20px;
  }

  .footer-section {
    margin-bottom: 25px;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 16px;
  }

  .footer-links {
    gap: 16px;
  }

  .support-info {
    font-size: 0.9rem;
  }
}
</style>
